import React, { useState } from 'react';
import { Button, Modal, Tooltip } from 'react-bootstrap';
import { t } from 'i18next';
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import IFile from 'interfaces/IFile';
import { MdForum } from "react-icons/md";

interface FileAssistantsModalProps {
    file: IFile
}

const FileAssistants = ({ file }: FileAssistantsModalProps) => {
    const [showModal, setShowModal] = useState<boolean>(false);

    return (
        <>
            {file.assistants &&
                <OverlayTrigger
                    placement="bottom"
                    overlay={<Tooltip id="button-tooltip-2">
                        <div>{t('file.contentModal.assistants')}</div>
                    </Tooltip>}
                >
                    <Button variant="outline-secondary"
                        size="sm"
                        onClick={() => {
                            setShowModal(true)
                        }
                        }>
                        <MdForum size={18} />
                    </Button>
                </OverlayTrigger>
            }
            <Modal show={showModal} onHide={() => setShowModal(false)} centered>
                <Modal.Header closeButton>
                    <Modal.Title>{t("file.contentModal.assistants")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ul className="list-group">
                    {file.assistants && file.assistants.map((assistant, index) => {
                        return (
                            <li key={index} className='list-group-item'>
                                <b>{assistant.name}</b>
                            </li>
                        )
                    })}
                    </ul>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default FileAssistants;