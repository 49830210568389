import React, {useEffect, useState} from 'react';
import {Link} from "react-router-dom";
import {Button, Stack, Table, Tooltip} from "react-bootstrap";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import {t} from "i18next";
import {FaFileCode, FaPlay} from "react-icons/fa";
import {FaFilePdf} from "react-icons/fa6";
import {IoMdDownload} from "react-icons/io";

import {usePermissions} from "hooks/usePermissions";
import PermissionCheck from "components/permissioncheck/permissioncheck";
import FilterSelect from "components/filters/FilterSelect";
import FilterText from "components/filters/FilterText";
import FilterDate from "components/filters/FilterDate";

import IFile from "interfaces/IFile";
import {Api} from "services/api";
import {bytesToSize, shortenFileName} from "services/utils";

import AddDocument from "./components/AddDocument";
import AddVideo from "./components/AddVideo";
import AddAudio from "./components/AddAudio";
import FileType, {TypeChoices} from "../../components/FileType";
import StatusBadge from "./components/StatusBadge";
import ContentModal from "./components/ContentModal";
import TopicsModal from "./components/TopicsModal";
import DeleteModal from "./components/DeleteModal";
import FileAssistants from "./components/FileAssistants";

import './FilePage.css';
import Rights from "./components/Rights";

const FilePage = () => {
    const {checkPermissions} = usePermissions();
    const [files, setFiles] = useState<IFile[]>([]);
    const [filteredFiles, setFilteredFiles] = useState<IFile[]>([]);
    const [filters, setFilters] = useState<any>({});

    const getList = async () => {
        const response = await Api.get<IFile[]>('file/')
        setFiles(response);
    }

    useEffect(() => {
        checkPermissions([
            'file.view_file',
            'file.view_document',
            'file.view_video',
            'file.view_audio',
            'file.add_file',
            'file.add_document',
            'file.add_video',
            'file.add_audio',
            'file.change_file',
            'file.delete_file',
            'file.delete_document',
            'file.delete_video',
            'file.delete_audio'
        ]);

        void getList();
    }, []);

    useEffect(() => {
        const needUpdate = files.filter((file) => file.status !== 'completed' && file.status !== 'failed');
        if (needUpdate.length) {
            setTimeout(getList, 2000);
        }
    }, [files]);

    useEffect(() => {
        const filtered = files.filter((file) => {
            let result = true;

            // Típus szerinti szűrés
            if (typeof filters.type !== 'undefined' && filters.type.length && !filters.type.includes(file.type)) {
                result = false;
            }

            // Cím szerinti szűrés
            if (typeof filters.title !== 'undefined' && filters.title.length && file.title.toLowerCase().indexOf(filters.title.toLowerCase()) === -1) {
                result = false;
            }

            // Dátum szerinti szűrés
            if (typeof filters.date !== 'undefined' && filters.date.length === 2) {
                const fileDate = new Date(file.date); // A file.date string konvertálása Date objektummá
                const startDate = filters.date[0] ? new Date(filters.date[0]) : null; // Kezdő dátum konvertálása Date objektummá
                let endDate = filters.date[1] ? new Date(filters.date[1]) : null;   // Vég dátum konvertálása Date objektummá

                // Az endDate időpontját a nap végére állítjuk (23:59:59)
                if (endDate) {
                    endDate.setHours(23, 59, 59, 999);
                }

                // Csak akkor szűrjük meg, ha mind a kezdő, mind a vég dátum meg van adva
                if (startDate && endDate) {
                    if (fileDate < startDate || fileDate > endDate) {
                        result = false;  // Ha a fileDate kívül esik az intervallumon, akkor false
                    }
                }
            }

            return result;
        });

        setFilteredFiles(filtered);
    }, [filters, files]);


    return (
        <PermissionCheck permission={['file.view_file', 'file.view_document', 'file.view_video', 'file.view_audio']}>
            <div className="video-add-buttons d-flex justify-content-between mt-2">
                <AddAudio getList={getList}/>
                <AddVideo getList={getList}/>
                <AddDocument getList={getList}/>

            </div>
            <div className="card shadow-sm mb-3 px-3 py-2 overflow-x-auto">
                <Table responsive="sm" hover className="align-middle">
                    <thead>
                    <tr>
                        <th>
                            {t("file.table.type")}
                            <FilterSelect field="type" fieldLabel={t("file.table.type")} choices={TypeChoices}
                                          filters={filters} setFilters={setFilters}/>
                        </th>
                        <th>
                            {t("file.table.date")}
                            <FilterDate filters={filters} setFilters={setFilters} field="date"
                                        fieldLabel={t("file.table.date")}></FilterDate>
                        </th>
                        <th>
                            {t("file.table.name")}
                            <FilterText filters={filters} setFilters={setFilters} field="title"
                                        fieldLabel={t("file.table.name")}></FilterText>
                        </th>
                        <th>
                            {t("file.table.status")}
                        </th>
                        <th className="text-end">
                            {t("file.table.size")}
                        </th>
                        <th className="text-end">
                            {t("file.table.operations")}
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    {filteredFiles.length === 0 && <tr>
                        <td colSpan={6} className="text-center">{t('file.table.empty')}</td>
                    </tr>}
                    {filteredFiles.map((file: IFile) => (
                        <tr key={file.id}>
                            <td className="text-center"><FileType file={file}/></td>
                            <td className="text-nowrap">{file.date}</td>
                            <td><span title={file.title}>{shortenFileName(file.title)}</span></td>
                            <td><StatusBadge file={file}/></td>
                            <td>{bytesToSize(file.total_media_size)}</td>
                            <td>
                                <Stack direction="horizontal" gap={2} className="justify-content-end mb-2">
                                    {file.type !== 'document' &&
                                        <OverlayTrigger
                                            placement="bottom"
                                            overlay={<Tooltip id="button-tooltip-2">
                                                <div>{t('audio.playAudio')}</div>
                                            </Tooltip>}
                                        >
                                            <Link to={file.type === 'youtube' ? file.url! : file.playUrl!}
                                                  target="_blank">
                                                <Button variant="outline-secondary" size="sm">
                                                    <FaPlay size={18}/>
                                                </Button>
                                            </Link>

                                        </OverlayTrigger>
                                    }

                                    {file.content &&
                                        <OverlayTrigger
                                            placement="bottom"
                                            overlay={<Tooltip id="button-tooltip-2">
                                                <div>{t('file.pdf_export')}</div>
                                            </Tooltip>}
                                        >
                                            <Link to={file.pdfUrl!} target="_blank">
                                                <Button variant="outline-secondary" size="sm">
                                                    <FaFilePdf size={18}/>
                                                </Button>
                                            </Link>
                                        </OverlayTrigger>
                                    }
                                    {file.content && file.type !== 'document' &&
                                        <OverlayTrigger
                                            placement="bottom"
                                            overlay={<Tooltip id="button-tooltip-2">
                                                <div>{t('file.pdf_srt_export')}</div>
                                            </Tooltip>}
                                        >
                                            <Link to={file.pdfSrtUrl!} target="_blank">
                                                <Button variant="outline-secondary" size="sm">
                                                    <FaFileCode size={18}/>
                                                </Button>
                                            </Link>
                                        </OverlayTrigger>
                                    }
                                    {file.type === 'document' &&
                                        <OverlayTrigger
                                            placement="bottom"
                                            overlay={<Tooltip id="button-tooltip-2">
                                                <div>{t('file.documentUrl')}</div>
                                            </Tooltip>}
                                        >
                                            <Link to={file.documentUrl!} target="_blank">
                                                <Button variant="outline-secondary" size="sm">
                                                    <IoMdDownload size={18}/>
                                                </Button>
                                            </Link>
                                        </OverlayTrigger>
                                    }
                                    {file.type === 'document' &&
                                        <TopicsModal file={file} type="summary"/>
                                    }
                                    <Rights file={file} />
                                    <FileAssistants file={file} />
                                    <DeleteModal file={file} getList={getList}/>
                                </Stack>
                                <Stack direction="horizontal" gap={2} className="justify-content-end">

                                    <ContentModal file={file} getList={getList}/>
                                    <TopicsModal file={file} type="topics"/>
                                    {file.type !== 'document' &&
                                        <TopicsModal file={file} type="summary"/>
                                    }
                                </Stack>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </Table>
            </div>
        </PermissionCheck>
    );
};

export default FilePage;