import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import Dropdown from 'react-bootstrap/Dropdown';

import "services/i18n/config";

import { Link, useNavigate } from "react-router-dom";
import { t, use } from "i18next";
import { useContext, useEffect, useState } from "react";
import DataContext from "context/DataContext";
import { IoMdSettings } from "react-icons/io";
import { IoLogOut } from "react-icons/io5";
import { MdOutlineLanguage } from "react-icons/md";
import { FaBell, FaCaretDown } from "react-icons/fa";
import { languages, useLanguage } from "context/LanguageContext";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Api } from "services/api";
import PermissionCheck from "components/permissioncheck/permissioncheck";

interface IUnreadMessages {
    count: number | 0;
}

const PageNavbar = () => {
    const { profile } = useContext(DataContext);
    const { language, setLanguage } = useLanguage();
    const [langMenuOpen, setLangMenuOpen] = useState<boolean>(false);
    const [unreadMessages, setUnreadMessages] = useState<number>(0);

    const navigate = useNavigate();

    const handleLangMenu = () => {
        setLangMenuOpen(!langMenuOpen);
    }

    const handleNavHome = () => {
        // sessionStorage.setItem("tabKey", "assistants");
        // window.location.reload();
        sessionStorage.removeItem("tabKey");
        window.location.href = "/";
    }

    const handleTrainingMessages = () => {
        sessionStorage.setItem("tabKey", "training");
        window.location.reload();
        window.location.href = "/";
    }

    const handleLangChange = (lang: string) => {
        localStorage.setItem('lang', lang);
        setLanguage(lang);
        setLangMenuOpen(false);
        window.location.reload();
    }

    const getUnreadMessages = async () => {
        if (!profile.id) return;

        Api.get<IUnreadMessages>('training-message/unread-messages').then((response) => {
            setUnreadMessages(response.count);
        });
    }

    // Ellenőrzi az olvasatlan üzeneteket
    useEffect(() => {
        const intervalId = setInterval(() => {
            //console.log('Check unread messages');
            getUnreadMessages();
        }, 10000); // 10 másodpercenként

        return () => clearInterval(intervalId); // Tisztítsd meg az időzítőt, amikor a komponens unmount-olódik
    }, []);

    return (
        <Navbar
            expand="lg"
            className="bg-body-tertiary shadow sticky-top"
        >
            <Container fluid>
                <Navbar.Brand>
                    <Link
                        to="/"
                        className="nav-link"
                        onClick={() => handleNavHome()}
                    >
                        {profile.company && profile.company.logo ? (
                        <img
                            src={profile.company.logo}
                            alt=""
                            style={{ height: "60px" }}
                        />) : (
                            <img
                                src="/images/cognitudo_logo.png"
                                alt=""
                                style={{ height: "60px" }}
                            />
                        )}
                    </Link>
                </Navbar.Brand>
                <div className="top-menu-items d-inline-flex align-items-center">
                    <div className="d-inline-flex align-items-center">
                        {profile.id && <div className="d-inline-flex text-end px-2 mb-0 profile-link">
                            <Link to="/profile" className="d-inline-flex align-items-center gap-2">
                                {profile.profile_image ? (<div className="profile-image d-inline-flex">
                                    <img src={profile.profile_image} alt="" />
                                </div>) :
                                    (<div className="profile-image d-inline-flex">
                                        <img src="/images/default-profile.png" alt="" />
                                    </div>
                                    )}
                                <b>{profile.username}</b>
                            </Link></div>}
                        {!profile.id && (
                            <Link
                                to="login"
                                className="nav-link ms-3 me-3"
                            >
                                {t("navbar.login")}
                            </Link>
                        )}
                        {profile.id && <PermissionCheck permission={['account.company_admin']}>
                            <div className="nav-link ms-3 me-3 position-relative"
                                style={{ cursor: "pointer" }}
                                onClick={(e) => handleTrainingMessages()}
                            >
                                {unreadMessages > 0 && <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill text-danger fw-bold">
                                    {unreadMessages}
                                    <span className="visually-hidden">unread messages</span>
                                </span>}
                                <FaBell size={18} />
                            </div>
                        </PermissionCheck>}
                        <OverlayTrigger
                            placement="bottom"
                            overlay={<Tooltip id="button-tooltip-2">
                                <div>{t('edit_url.settings')}</div>
                            </Tooltip>}
                        >
                            <Link
                                to="edit-url"
                                className="nav-link ms-2 me-2"
                            >
                                <IoMdSettings size={18} />
                            </Link>
                        </OverlayTrigger>
                        <div className="nav-link ms-2 me-2 lang-dropdown">
                            <div className="lang-block d-inline-flex align-items-center justify-content-start"
                                onClick={handleLangMenu}>
                                <MdOutlineLanguage size={18} />
                                <span className='active-lang'>({language})</span>
                                <FaCaretDown />
                            </div>
                            <Dropdown.Menu show={langMenuOpen} align="end" flip={false}>
                                <Dropdown.Header>{t('page_language')}:</Dropdown.Header>
                                {Object.entries(languages).map(([key, name]) => (
                                    <Dropdown.Item key={key} eventKey={key} onClick={() => handleLangChange(key)}
                                        active={language == key ? true : false}>
                                        {name}
                                    </Dropdown.Item>
                                ))}
                            </Dropdown.Menu>
                        </div>
                        {!!profile.id && (
                            <OverlayTrigger
                                placement="bottom"
                                overlay={<Tooltip id="button-tooltip-2">
                                    <div>{t('navbar.logout')}</div>
                                </Tooltip>}
                            >
                                <Link
                                    to="logout"
                                    className="nav-link ms-2 me-2"
                                >
                                    <IoLogOut size={20} />
                                </Link>
                            </OverlayTrigger>
                        )}
                    </div>
                </div>
            </Container>
        </Navbar>
    );
};
export default PageNavbar;
