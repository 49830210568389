import { useContext, useState } from 'react';
import { Button, Dropdown, DropdownButton, Modal } from "react-bootstrap";
import { BsLayoutSidebar } from "react-icons/bs";
import SidePanel from "./SidePanel";
import MessageList from "./MessageList";
import Form from "./Form";
import toast, { Toast, Toaster } from "react-hot-toast";
import ChatContext from "../context/ChatContext";
import PermissionCheck from 'components/permissioncheck/permissioncheck';
import { MdDateRange, MdFolderOpen, MdGroup, MdKeyboardBackspace } from "react-icons/md";
import { t } from "i18next";
import Files from "./Files";
import Threads from "./Threads";
import { ro } from 'date-fns/locale';
import router from 'services/router';
import { set } from 'date-fns';

const user_id = parseInt(localStorage.getItem('user_id') ?? '0');

const Main = () => {

    const { assistant, setThreadId } = useContext(ChatContext);

    const [showThreads, setShowThreads] = useState(false);
    const [showFiles, setShowFiles] = useState(false);
    const [showFilesModal, setShowFilesModal] = useState(false);
    const [showThreadsModal, setShowThreadsModal] = useState(false);

    const toggleThreads = () => {
        setShowThreads(prevState => !prevState);
        setShowFiles(false);
    }

    const toggleFiles = () => {
        setShowFiles(prevState => !prevState);
        setShowThreads(false);
    }

    const handleNavBack = () => {
        //router.navigate('/');
        window.location.href = '/';
    }

    const clearHistory = () => {
        setThreadId('');
        localStorage.removeItem('thread_id')
        toast.dismiss('clear-history-toast');
        window.location.reload();
    };

    const handleClearHistory = () => {
        toast.dismiss('clear-history-toast');
        toast.custom(<div className="p-4 shadow border-1 rounded-2" style={{ 'background': '#fff' }}>
            <div className="mb-3 fw-bold">{t("chat.confirm-start-new-chat")}</div>
            <div className="d-flex justify-content-around align-items-center">
                <Button
                    variant="primary"
                    size="sm"
                    onClick={() => clearHistory()}
                >
                    {t("chat.yes")}
                </Button>
                <Button
                    className="ms-3"
                    variant="secondary"
                    size="sm"
                    onClick={() => toast.dismiss()}
                >
                    {t("chat.cancel")}
                </Button>
            </div>
        </div>, {
            id: 'clear-history-toast',
            className: 'clear-history-toast',
            position: 'bottom-center',
            duration: 10000,
        });
    };

    const handleClose = () => {
        setShowThreads(false);
        setShowFiles(false);
    };

    return (
        <>
            <PermissionCheck permission={'chat.view_chat'} force={!assistant.id || assistant.owner_id === user_id}>
                <div id="cc-hamburger-menu" className="d-md-none w-100">
                    <Dropdown className="mx-2">
                        <Dropdown.Toggle id="dropdown-autoclose-true">
                            <BsLayoutSidebar size={24} />
                        </Dropdown.Toggle>

                        <Dropdown.Menu className='shadow-sm w-100'>
                            <Dropdown.Item as="button"
                                className='btn-outline-primary d-flex justify-content-start align-items-center'
                                onClick={handleNavBack}>
                                {/* <MdKeyboardBackspace size={24} /> */}
                                {t('chat.backButtonLabel')}
                            </Dropdown.Item>
                            <Dropdown.Item as="button" className='btn-outline-primary d-flex justify-content-start align-items-center' onClick={handleClearHistory}>
                                {/* <MdGroup size={24} /> */}
                                {t('chat.newThreadButtonLabel')}
                            </Dropdown.Item>

                            <Dropdown.Item as="button"
                                className='btn-outline-primary d-flex justify-content-start align-items-center'
                                onClick={() => setShowThreadsModal(true)}>
                                {/* <MdDateRange size={24} /> */}
                                {t('chat.threadsButtonLabel')}
                            </Dropdown.Item>

                            <Dropdown.Item as="button"
                                className='btn-outline-primary d-flex justify-content-start align-items-center'
                                onClick={() => setShowFilesModal(true)}>
                                {/* <MdFolderOpen size={24} /> */}
                                {t('chat.filesButtonLabel')}
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>

                    <Modal className="full-height-modal"
                        show={showFilesModal}
                        onHide={() => setShowFilesModal(false)}
                        centered
                        scrollable={true}>
                        <Modal.Header closeButton>
                            <Modal.Title>{t('chat.filesButtonLabel')}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Files handleClose={handleClose} header={false} />
                        </Modal.Body>
                    </Modal>

                    <Modal className="full-height-modal"
                        show={showThreadsModal}
                        onHide={() => setShowThreadsModal(false)}
                        centered
                        scrollable={true}>
                        <Modal.Header closeButton>
                            <Modal.Title>{t('chat.threadsButtonLabel')}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Threads handleClose={handleClose} header={false} />
                        </Modal.Body>
                    </Modal>
                </div>

                <div id="cc-chat">
                    <div id="cc-toolbar" className={`bg-body-tertiary ${showThreads || showFiles ? 'shadow-sm' : 'shadow-sm'}`}>
                        <Button onClick={handleNavBack} variant={'outline-primary'}>
                            <MdKeyboardBackspace size={32} />
                            {t('chat.backButtonLabel')}
                        </Button>
                        <Button onClick={handleClearHistory} variant={'outline-primary'}>
                            <MdGroup size={32} />
                            {t('chat.newThreadButtonLabel')}
                        </Button>
                        <Button onClick={toggleThreads} variant={'outline-primary'} className={showThreads ? 'cc-toolbar-btn-active' : ''}>
                            <MdDateRange size={32} />
                            {t('chat.threadsButtonLabel')}
                        </Button>
                        <Button onClick={toggleFiles} variant={'outline-primary'} className={showFiles ? 'cc-toolbar-btn-active' : ''}>
                            <MdFolderOpen size={32} />
                            {t('chat.filesButtonLabel')}
                        </Button>
                    </div>

                    <div id="cc-side-panel">
                        {(showFiles || showThreads) && <div id="cc-side-panel-inner">
                            {showFiles && <Files handleClose={handleClose} header={true} />}
                            {showThreads && <Threads handleClose={handleClose} header={true} />}
                        </div>}
                    </div>

                    <div id="cc-main-panel">
                        <MessageList showAssistantDetails={true}
                            showBackButton={true}
                            showSourcesButton={true}
                        />
                        <Form showBotsBtn={true} />
                    </div>
                </div>
            </PermissionCheck>
        </>
    )
};

export default Main;
