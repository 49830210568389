import { Button, Tooltip, OverlayTrigger } from "react-bootstrap";
import { PiSpeakerSimpleHighFill, PiSpeakerSimpleSlashFill } from "react-icons/pi";
import { BsFillMicFill, BsFillMicMuteFill } from "react-icons/bs";
import ChatContext from "../context/ChatContext";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useSendMessage } from "../hooks/useSendMessage";
import { t } from "i18next";
import toast, { Toaster } from "react-hot-toast";

let recognition: any;
let browserSupported = true;

if ('SpeechRecognition' in window || 'webkitSpeechRecognition' in window) {
    recognition = new ((window as any).SpeechRecognition || (window as any).webkitSpeechRecognition)();
    recognition.lang = "hu-HU";
    recognition.continuous = true;
    recognition.interimResults = true;
    recognition.maxAlternatives = 1;
} else {
    console.error('Speech recognition not supported in this browser.');
    browserSupported = false;
}

const Audio = () => {

    const [recognitionSupported, setRecognitionSupported] = useState<boolean>(true);

    const timerRef = useRef<NodeJS.Timeout>();
    const sendMessage = useSendMessage();
    const {
        audioState,
        setAudioState,
        setMessage
    } = useContext(ChatContext);

    const handleClickRecognition = () => {
        if (browserSupported) {
            setAudioState(audioState == 'disabled' ? 'microphone' : 'disabled');
        } else {
            //console.error('Speech recognition not supported in this browser.');
            toast.error(t('chat.speech-recognition-not-supported'));
        }
    };

    const handleOnEnded = () => {
        setAudioState('disabled');
    };

    useEffect(() => {
        if (browserSupported) {
            // Clear the interval when the component unmounts
            return () => {
                clearTimeout(timerRef.current);
                recognition.stop();
            }
        }
        else {
            setRecognitionSupported(false);
        }
    }, []);

    useEffect(() => {
        if (browserSupported) {
            if (audioState == 'microphone') {
                recognition.start();
            } else {
                recognition.stop();
            }
        }
    }, [audioState]);

    if (browserSupported) {
        recognition.onresult = (event: any) => {
            let text = Array.from(event.results)
                .map((result: any) => result[0])
                .map((result) => result.transcript)
                .join("");
            setMessage(text);
            if (event.results[event.results.length - 1].isFinal) {
                clearTimeout(timerRef.current);
                timerRef.current = setTimeout(() => {
                    setAudioState('disabled');
                    setMessage('');
                    sendMessage();
                }, 2000);
            }
        };
    }

    return (
        <>
            <OverlayTrigger
                placement="top"
                overlay={<Tooltip id="button-tooltip-2">
                    <div>{recognitionSupported === false ? t('chat.speech-to-text-not-supported') : t('chat.speech-to-text')}</div>
                </Tooltip>}
            >
                <Button onClick={handleClickRecognition}
                    disabled={recognitionSupported === false ? true : false}
                    style={{ 'pointerEvents': 'auto', 'cursor': `${recognitionSupported === false ? 'not-allowed' : 'pointer'}` }}>
                    {audioState == 'microphone' ? <BsFillMicFill /> : <BsFillMicMuteFill />}
                </Button>
            </OverlayTrigger>
            <Toaster
                position="bottom-center"
            />
        </>
    );
};

export default Audio;