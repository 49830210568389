import {Button, Form as HtmlForm, Spinner, Stack, Tooltip, OverlayTrigger} from "react-bootstrap";
import {t} from "i18next";
import {BsSendFill} from "react-icons/bs";
import React, {FormEvent, useContext, useEffect, useRef, KeyboardEvent} from "react";
import ChatContext from "../context/ChatContext";
import {useSendMessage} from "../hooks/useSendMessage";
import Bots from "./Bots";
import History from "./History";
import Audio from "./Audio";
import AssistantDetails from "./AssistantDetails";

const Form = ({showBotsBtn}: { showBotsBtn: boolean }) => {
    const {message, setMessage, isLoading, setThreadId, selectedMessage, setSelectedMessage} = useContext(ChatContext);
    const sendMessage = useSendMessage();

    const textInput = useRef<HTMLTextAreaElement | null>(null);
    const submitButton = useRef<HTMLButtonElement | null>(null);

    useEffect(() => {
        if (selectedMessage) {
            setMessage(selectedMessage);
        }
    }, [selectedMessage, setMessage]);

    useEffect(() => {
        if (message === selectedMessage && message.trim()) {
            sendMessage();
            setMessage('');
            setSelectedMessage('');
        }
    }, [message, selectedMessage, sendMessage, setMessage]);

    useEffect(() => {
        if (!isLoading && textInput.current) {
            textInput.current.focus()
        }
    }, [isLoading]);


    const handleSendMessage = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (message.trim()) {
            sendMessage();
            setMessage('');
        }
    }

    const adjustTextareaHeight = function () {
        const textarea = textInput.current;
        if (textarea) {
            textarea.style.height = '1.5em'; // Reset to single line height
            textarea.style.height = `${textarea.scrollHeight}px`;
        }
    };
    useEffect(() => {
        adjustTextareaHeight();
    }, [message]);

    const handleKeyDown = (event: KeyboardEvent<HTMLTextAreaElement>) => {
        if (event.key === 'Enter' && !event.shiftKey) {
            event.preventDefault();
            if (submitButton.current) {
                submitButton.current.click();
            }
        }
    };

    return (
        <div className="send-message-box w-100 z-3">
            <div className="container-fluid py-3 d-flex gap-3">
                <Audio/>
                <div className="d-flex flex-grow-1 gap-3 align-items-end">
                    <form onSubmit={handleSendMessage} className="flex-grow-1 d-flex gap-3 align-items-end">
                        <HtmlForm.Control
                            as="textarea"
                            placeholder={t("chat.input-placeholder")}
                            aria-describedby="messageHelpBlock"
                            onChange={(e) => {
                                setMessage(e.target.value);
                            }}
                            onInput={(e) => {
                                adjustTextareaHeight()
                            }}
                            onKeyDown={handleKeyDown}
                            value={message}
                            disabled={isLoading}
                            ref={textInput}
                            tabIndex={1}
                            rows={1}
                            style={{resize: 'none', overflow: 'hidden'}}
                        />
                        <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip id="button-tooltip-2">
                                <div>{t('chat.send')}</div>
                            </Tooltip>}
                        >
                            <Button
                                variant="dark"
                                type="submit"
                                ref={submitButton}
                            >
                                {isLoading ? (
                                    <div>
                                        <Spinner
                                            as="span"
                                            animation="border"
                                            size="sm"
                                            role="status"
                                            aria-hidden="true"
                                        />
                                        <span className="visually-hidden">Loading...</span>
                                    </div>
                                ) : (
                                    <BsSendFill/>
                                )}
                            </Button>
                        </OverlayTrigger>
                    </form>

                    {/* <History></History>
                    {showBotsBtn &&
                        <Bots/>
                    } */}
                </div>
            </div>
        </div>
    )
}
export default Form;